@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: "Fredoka", sans-serif;
}